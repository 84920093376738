import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import './Login.css';

function Login({ setIsAuthenticated, setUserEmail }) {
  const [email, setEmailInput] = useState('');
  const [password, setPasswordInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Check authentication status on component mount
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/check-auth`, { withCredentials: true });
        if (response.data.isAuthenticated) {
          // Redirect to profile if already authenticated
          setIsAuthenticated(true);
          setUserEmail(response.data.email);
          navigate('/profile');
        } else {
          setLoading(false); // Stop loading if not authenticated
        }
      } catch (error) {
        console.error('Error checking authentication status:', error);
        setLoading(false); // Stop loading even if an error occurs
      }
    };

    checkAuthStatus();
  }, [navigate, setIsAuthenticated, setUserEmail]);

  const handleLogin = async () => {
    setErrorMessage(''); // Clear previous errors

    try {
      // Send login request to the backend
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/login`,
        { email, password },
        { withCredentials: true } // Ensure session cookies are sent
      );

      if (response.status === 200) {
        // After successful login, fetch the user profile to set state
        const profileResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/check-auth`, { withCredentials: true });

        if (profileResponse.data.isAuthenticated) {
          // Set the user email and authenticated state
          localStorage.setItem('email', profileResponse.data.email);
          localStorage.setItem('userId', profileResponse.data.userId);

          // Update authentication state in App.js
          setIsAuthenticated(true);
          setUserEmail(profileResponse.data.email);

          // Redirect to profile page after login
          navigate('/profile');
        } else {
          setErrorMessage('Failed to retrieve user profile after login.');
        }
      } else {
        setErrorMessage('Invalid response from server');
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message || 'Login failed');
      } else {
        setErrorMessage('An error occurred during login');
      }
      console.error('Error logging in:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="login-container">
      <h2>Login</h2>
      <div className="form-group">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmailInput(e.target.value)}
        />
      </div>
      <div className="form-group">
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPasswordInput(e.target.value)}
        />
      </div>
      <div className="form-group">
        <button onClick={handleLogin}>Login</button>
      </div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <div className="register-link">
        <p>
          Not registered yet? <Link to="/register">Sign up here</Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
